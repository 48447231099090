.rsvpButton {
	font-family: "HelveticaNeue-Light"/*, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif*/; 

	background: #92844e;
	min-height: 5vh;
	/*min-width: 15vw;*/
	border-width: .100rem;
	font-weight: 600;
	/*border-radius: 35px;*/
	font-size: 25px;
	/*border-color: #493c06;*/
	border-width: 2px;

}

@media only screen and (max-width: 600px) {
	.rsvpButton{
	font-family: "HelveticaNeue-Light"/*, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif*/; 

	background: #92844e;
	min-height: 5vh;
	border-width: .100rem;
	font-weight: 600;
	border-radius: 35px;
	font-size: 25px;
	padding-left: 10%;
	padding-right: 10%;
	}
}

.calendarButton{
	font-family: "HelveticaNeue-Light"/*, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif*/;
	background-color: transparent;
	min-height: 5vh;
	min-width: 15vw;
	border-width: .100rem;
	font-weight: 600;
	font-size: 25px;
	border-color: #493c06;
	border-width: 2px;
}

@media only screen and (max-width: 600px) {
	.calendarButton {
	font-family: "HelveticaNeue-Light"/*, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif*/;
	background-color: transparent;
	min-height: 3vh;
	min-width: 12vw;
	border-width: .100rem;
	font-weight: 600;
	border-radius: 20px;
	font-size: 20px;
	padding-left: 5%;
	padding-right: 5%;
	}
}

.mapsButton{
	font-family: "HelveticaNeue-Light"/*, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif*/;
	background-color: transparent;
	min-height: 5vh;
	min-width: 15vw;
	border-width: .100rem;
	font-weight: 600;
	font-size: 25px;
	border-color: #493c06;
	border-width: 2px;
}

@media only screen and (max-width: 600px) {
	.mapsButton {
	font-family: "HelveticaNeue-Light"/*, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif*/;
	background-color: transparent;
	min-height: 3vh;
	min-width: 12vw;
	border-width: .100rem;
	font-weight: 600;
	border-radius: 20px;
	font-size: 20px;
	padding-left: 5%;
	padding-right: 5%;
	}
}


