body{

  
  background: /*url('./images/Logodesign_Small.png'),*/ url('./images/Myproject.png'), url('./images/Myproject1.png')/*, url('./images/texture.jpg')*/;
  background-size: /*cover*/ auto, auto/*, cover*/;
  background-position: /*center*/ left bottom, right top/*, center*/;
  background-repeat: /*no-repeat*/ no-repeat, no-repeat/*, no-repeat*/;
  background-color: #fffff;

  }


@media only screen and (max-width: 600px) {
  body {
    background: /*url('./images/Logodesign_Small.png'),*/ url('./images/Myproject.png'), url('./images/Myproject1.png'), url('./images/texture.jpg');
    background-size: /*cover*/ auto, auto, cover;
    background-position: /*center*/left bottom, right top, center;
    background-repeat: /*no-repeat*/ no-repeat, no-repeat, no-repeat;
  }
}


h1 {
  background: url('./images/Logodesign_Small.png');
  background-size: center;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom:60px;
  margin-bottom:30px;
  padding-top:110px;
  font-family: 'D.Signature';
  font-weight: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  color: #493c06;
}

@media only screen and (max-width: 600px) {
  h1 {
  background: url('./images/Logodesign_Small1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom:0px;
  margin-bottom:0px;
  /*height: 330px;*/
  font-family: 'D.Signature';
  font-size: 50px;
  color: #493c06;
  
  }
}

.centerText {
  font-family: 'D.Signature';
  font-size: 50px;
  color: #493c06;
  word-break: keep-all;
}

@media only screen and (max-width: 400px) {
  .centerText {
  font-family: 'D.Signature';
  /*min-height: 1vh;*/
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  font-size: 35px;
  color: #493c06;
  }
}


.rsvpLink{
    font-family: "HelveticaNeue-Light"/*, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif*/; 
    font-weight: 200;
    font-size: 30px;
    width: 350px;
  }

@media only screen and (max-width: 600px) {
  .rsvpLink{
    font-family: "HelveticaNeue-Light"/*, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif*/; 
    font-weight: 200;
    font-size: 20px;
  }
}

