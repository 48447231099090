
body{

  background-color: #e4e4e4;

/*  background: url('./images/texturePNG.png');
  background-size: absolute;
  background-position: left;
  background-repeat: repeat;*/
}

@media only screen and (max-width: 600px) {
  body {
    background: url('./images/texturePNG.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}



h1 {
  font-family: 'D.Signature';
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 65px;
  color: #493c06;
}

@media only screen and (max-width: 600px) {
  h1 {
  word-break: keep-all;
  font-family: 'D.Signature';
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #493c06;
  
  }
}

.centerText {
  font-family: 'D.Signature';
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #493c06;
}

@media only screen and (max-width: 400px) {
  .centerText {
  font-family: 'D.Signature';
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #493c06;
  }
}